import React from 'react';
import { UnitOfMeasure } from '../../enums/unit-of-measure.enum';
import { MeasurementType } from '../../enums/measurement-type.enum';

import { ComponentConnectorFactory } from 'store/component-connector';
import { select } from 'store/selectors/state.selectors';

interface OwnProps {
  type: MeasurementType;
}
interface ConnectStateProps {
  unitOfMeasure: UnitOfMeasure;
}

type Props = OwnProps & ConnectStateProps;

export const getUnit = (unit: UnitOfMeasure, type: MeasurementType) => {
  const isMetric = unit === UnitOfMeasure.Metric;

  switch (type) {
    case MeasurementType.Distance:
      return isMetric ? 'km' : 'mi';
    case MeasurementType.Weight:
      return isMetric ? 'kg' : 'lbs';
    case MeasurementType.Volume:
      return isMetric ? 'm³' : 'ft³';
    case MeasurementType.Length:
      return isMetric ? 'm' : 'ft';
    case MeasurementType.SmallLength:
      return isMetric ? 'cm' : 'in';
    case MeasurementType.Temperature:
      return isMetric ? '˚C' : '˚F';
  }
};

export const UnitLabelComponent = ({ unitOfMeasure, type }: Props) => (
  <span>{getUnit(unitOfMeasure, type)}</span>
);

export const UnitLabel = ComponentConnectorFactory<OwnProps, ConnectStateProps>()
  .combineStateSelectors(select.auth.user.unitOfMeasure)
  .connect(UnitLabelComponent);
